import React from 'react'

import Link from '../Link'

const emailRegex = new RegExp(/^\S+@\S+$/i)
const requireText = (val) => val === undefined || val === ''
const requireEmail = (val) => !emailRegex.test(val)

const requireImage = (val) => val === undefined || val.length === 0

const fullName = {
  id: 'full-name-input',
  type: 'input-text',
  text: 'Full name',
  validate: {
    pattern: requireText,
    message: 'Name required',
  },
}

const orderNumber = {
  id: 'order-number-input',
  type: 'input-text',
  text: 'Order number',
  validate: {
    pattern: requireText,
    message: 'Order number required',
  },
}

const companyName = {
  id: 'company-name-input',
  type: 'input-text',
  text: 'Company name',
  validate: {
    pattern: requireText,
    message: 'Company name required',
  },
}

const shippingAddress = {
  id: 'shipping-address-input',
  type: 'input-text',
  text: 'Shipping address',
  validate: {
    pattern: requireText,
    message: 'Shipping address required',
  },
}

const trackingLink = {
  id: 'tracking-link-input',
  type: 'input-text',
  text: 'Tracking link',
}

const dateNeeded = {
  id: 'date-input',
  type: 'input-text',
  text: 'When does this order need to arrive at your shipping destination',
}

const submitButton = {
  id: 'submit-button',
  type: 'submit',
  text: 'Submit',
}

const email = {
  id: 'email-input',
  type: 'input-text',
  text: 'Email',
  validate: {
    pattern: requireEmail,
    message: 'Email invalid',
  },
}

const phone = {
  id: 'phone-input',
  type: 'input-text',
  text: 'Contact number',
  validate: {
    pattern: requireText,
    message: 'Phone invalid',
  },
}

const message = {
  id: 'message-input',
  type: 'input-textarea',
  text: 'Message',
  validate: {
    pattern: requireText,
    message: 'Message invalid',
  },
}

const productSelectReturn = {
  id: 'product-select-return',
  type: 'select-multi',
  text: 'Select Returning Product(/s)',
  options: [
    'Carry On Classic',
    'Checked Classic',
    'Checked Plus Classic',
    'Carry Me',
    'Carry All Weekender',
    'Carry All Backpack',
    'Carry All Tote',
    '4 Packing cells',
    '8 Packing cells',
    'Carry On Pro',
    'Carry All Daypack',
    'Carry All Sling',
    'Carry All Pocket',
    'Daybreak Weekender',
    'Daybreak Backpack',
    'Daybreak Tote',
    'Power Bank',
    'Carry On Light',
    'Drink Bottle',
    'Phone Case',
    'Carry On Trunk',
    'Checked Trunk',
    'Carry All Weekender Plus',
    'Notebooks',
    'Luggage Tags',
    'Pro Snapsleeve',
    'Tech Kit',
    'Luggage Covers',
  ],
}

const productSelectExchange = {
  ...productSelectReturn,
  text: 'Select Exchange Product(/s)',
  id: 'product-select-exchange',
}

const productSelectFaulty = {
  ...productSelectReturn,
  text: 'Select Faulty Product(/s)',
  id: 'product-select-faulty',
}

const uploadImage = {
  id: 'upload-image',
  type: 'upload-image',
}

const requiredUploadImage = {
  id: 'upload-image',
  type: 'upload-image',
  validate: {
    pattern: requireImage,
    message: 'Image required',
  },
}

const formData = [
  {
    condition: (state) => state['sent'] === true,
    value: [
      {
        id: 'sent-text',
        type: 'text',
        text: (
          <>
            Thank you for sending through your enquiry, we will be in contact
            via email.
          </>
        ),
      },
    ],
  },
  {
    condition: (state) => state['sent'] !== true,
    value: [
      {
        id: 'category-select',
        type: 'select',
        text: 'Category',
        options: [
          'Order Status',
          'Returns',
          'Exchange',
          'Warranty Claim',
          'Product Questions',
          'Availability',
          'Corporate Orders',
          'Other',
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Order Status',
        value: [
          {
            id: 'order-status-select',
            type: 'select',
            text: 'Type',
            options: [
              'Where’s my order?',
              'Shipping delay with order',
              'Order misplaced or incorrectly delivered',
              'My issue isnt listed here',
            ],
          },
          {
            condition: (state) =>
              state['order-status-select'] === 'Where’s my order?',
            value: [
              {
                id: 'where-order-text',
                type: 'text',
                text: (
                  <>
                    We appreciate your patience in regards to your July order.
                    You can find all shipping & delivery information{' '}
                    <Link
                      styles={{
                        textDecoration: 'underline',
                      }}
                      to="/faqs/#shipping-and-delivery"
                    >
                      here
                    </Link>
                    .<br />
                    Once your order has been dispatched, you’ll receive an email
                    with tracking information.
                  </>
                ),
              },
              {
                id: 'didnt-solve-text',
                type: 'button',
                text: "Didn't solve your issue?",
                handle: ({ setValue }) =>
                  setValue((prev) => ({
                    ...prev,
                    'order-status-select': 'My issue isnt listed here',
                  })),
              },
            ],
          },
          {
            condition: (state) =>
              state['order-status-select'] === 'Shipping delay with order',
            value: [
              {
                id: 'delays-text',
                type: 'text',
                text: 'If your order is delayed for a significant period, we advise you to contact the designated courier shown on your tracking and place a consignment enquiry. Once you’ve lodged a customer enquiry, please contact us if you still don’t receive any updates within the next 48-72hrs.',
              },
              {
                id: 'didnt-solve-text',
                type: 'button',
                text: "Didn't solve your issue?",
                handle: ({ setValue }) =>
                  setValue((prev) => ({
                    ...prev,
                    'order-status-select': 'My issue isnt listed here',
                  })),
              },
            ],
          },
          {
            condition: (state) =>
              state['order-status-select'] ===
              'Order misplaced or incorrectly delivered',
            value: [
              fullName,
              orderNumber,
              trackingLink,
              email,
              message,
              uploadImage,
              submitButton,
            ],
          },
          {
            condition: (state) =>
              state['order-status-select'] === 'My issue isnt listed here',
            value: [
              fullName,
              orderNumber,
              email,
              message,
              uploadImage,
              submitButton,
            ],
          },
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Returns',
        value: [
          {
            id: 'return-policy-text',
            type: 'text',
            text: (
              <>
                Before processing your product return, please note:{' '}
                <b>all returned items must be unused and non personalised.</b>{' '}
                <br />
                You can find more information{' '}
                <Link
                  styles={{ textDecoration: 'underline' }}
                  to="/faqs/#returns-and-exchanges"
                >
                  here
                </Link>
                .
              </>
            ),
          },
          fullName,
          orderNumber,
          email,
          productSelectReturn,
          message,
          requiredUploadImage,
          submitButton,
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Exchange',
        value: [
          {
            id: 'return-policy-text',
            type: 'text',
            text: (
              <>
                Before processing your product exchange, please note:{' '}
                <b>all returned items must be unused and non personalised.</b>{' '}
                <br />
                You can find more information{' '}
                <Link
                  styles={{ textDecoration: 'underline' }}
                  to="/faqs/#returns-and-exchanges"
                >
                  here
                </Link>
                .
              </>
            ),
          },
          fullName,
          orderNumber,
          email,
          productSelectReturn,
          productSelectExchange,
          message,
          requiredUploadImage,
          submitButton,
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Warranty Claim',
        value: [
          {
            id: 'warranty-select',
            type: 'select',
            text: 'Type',
            options: [
              'CASE IS LOCKED/WON’T OPEN',
              'WHEEL',
              'CRACK/DENT',
              'COSMETIC DAMAGE',
              'OTHER',
            ],
          },
          {
            condition: (state) =>
              state['warranty-select'] !== 'CASE IS LOCKED/WON’T OPEN' &&
              state['warranty-select'] !== 'COSMETIC DAMAGE' &&
              state['warranty-select'] !== undefined,
            value: [
              fullName,
              email,
              orderNumber,
              productSelectFaulty,
              message,
              uploadImage,
              submitButton,
            ],
          },
          {
            condition: (state) =>
              state['warranty-select'] === 'COSMETIC DAMAGE',
            value: [
              {
                condition: (state) =>
                  state['cosmetic-select-not-solved'] !== true,
                value: [
                  {
                    id: 'cosmetic-text',
                    type: 'text',
                    text: (
                      <>
                        We understand your concerns and your view that this case
                        hasn't met your expectations on wear and tear. <br />
                        <br />
                        Cosmetic Damages are a part of all travel unfortunately
                        though we like to seem them as memories of your journey.
                        The extended warranty does not cover any defects caused
                        by improper use, any bumps, scratches, scuffs or general
                        wear and tear generated from using the case and have no
                        impact on how the case is used or its functionality.
                        <br />
                        <br />
                        You can find more information{' '}
                        <Link
                          styles={{
                            textDecoration: 'underline',
                          }}
                          to="/faqs/#product-warranties"
                        >
                          here
                        </Link>
                        .<br />
                        <br />
                        <b>Scratches on Bumpers</b>
                        <br />
                        <br />
                        Essentially, the main functional purpose of the bumpers
                        is to provide structural integrity to the case and
                        ensure that your belongings are protected from the bumps
                        and rough handling that your suitcase may sustain from
                        baggage handlers. When your suitcase does get checked
                        in, your suitcase can be thrown around which may result
                        in cosmetic damages to the bumpers. Unfortunately, these
                        cosmetic damages are not covered within our Lifetime
                        Warranty or under our 100 Day risk-free returns policy.
                      </>
                    ),
                  },
                  {
                    id: 'didnt-solve-text',
                    type: 'button',
                    text: "Didn't solve your issue?",
                    handle: ({ setValue }) =>
                      setValue((prev) => ({
                        ...prev,
                        'cosmetic-select-not-solved': true,
                      })),
                  },
                ],
              },
              {
                condition: (state) =>
                  state['cosmetic-select-not-solved'] === true,
                value: [
                  fullName,
                  email,
                  orderNumber,
                  productSelectFaulty,
                  message,
                  uploadImage,
                  submitButton,
                ],
              },
            ],
          },
          {
            condition: (state) =>
              state['warranty-select'] === 'CASE IS LOCKED/WON’T OPEN',
            value: [
              {
                id: 'lock-type-select',
                type: 'select',
                text: 'Type',
                options: [
                  'TSA Lock - Classic Article',
                  'TSA Lock - Trunk Article',
                ],
              },
              {
                condition: (state) =>
                  state['lock-type-select'] === 'TSA Lock - Classic Article',
                value: [
                  {
                    condition: (state) =>
                      state['lock-type-select-not-solved'] !== true,
                    value: [
                      {
                        id: 'where-order-text',
                        type: 'text',
                        text: (
                          <>
                            <b>How to Set Code</b> <br />
                            <br />
                            The default code is set to 000. To change push a
                            pointed object into the small square button next to
                            the numbers and set your own code. <br />
                            <br />
                            To confirm, squeeze the slider button. To release
                            the zips from the lock, align the numbers to your
                            code and squeeze the slider button. <br />
                            <br />
                            <b>Forgotten Code</b> <br />
                            <br />
                            This is a common issue we’ve found with our
                            customers. <br />
                            <br />
                            <b>The bad news</b>: there's no trick to this -
                            unfortunately you have to go through every single
                            code one by one until you crack it. <br />
                            <b>The good news</b>: It only takes 15 minutes!{' '}
                            <br />
                            <br />
                            We do this around 4-5 times a week (can you believe
                            it!). Firstly, start at a location near where you
                            think it would be, and go from there. <br />
                            <br />
                            For example: If your code could have been 444, then
                            start at 300, 301, 302, 303, etc and go up until you
                            do all the numbers. <br />
                          </>
                        ),
                      },
                      {
                        id: 'didnt-solve-text',
                        type: 'button',
                        text: "Didn't solve your issue?",
                        handle: ({ setValue }) =>
                          setValue((prev) => ({
                            ...prev,
                            'lock-type-select-not-solved': true,
                          })),
                      },
                    ],
                  },
                  {
                    condition: (state) =>
                      state['lock-type-select-not-solved'] === true,
                    value: [
                      fullName,
                      email,
                      orderNumber,
                      productSelectFaulty,
                      message,
                      uploadImage,
                      submitButton,
                    ],
                  },
                ],
              },
              {
                condition: (state) =>
                  state['lock-type-select'] === 'TSA Lock - Trunk Article',
                value: [
                  {
                    condition: (state) =>
                      state['lock-type-select-not-solved'] !== true,
                    value: [
                      {
                        id: 'where-order-text',
                        type: 'text',
                        text: (
                          <>
                            <b>How to Set Code</b> <br />
                            <br />
                            To set your code, simply select the 3 digit
                            combination and then push down the lock slider to
                            SET. Once changed, switch back to OFF. <br />
                            <br />
                            Double check to confirm and avoid locking yourself
                            out! <br />
                            <br />
                            <b>Forgotten Code</b> <br />
                            <br />
                            This is a common issue we’ve found with our
                            customers. <br />
                            <br />
                            <b>The bad news</b>: there's no trick to this -
                            unfortunately you have to go through every single
                            code one by one until you crack it. <br />
                            <b>The good news</b>: It only takes 15 minutes!{' '}
                            <br />
                            <br />
                            We do this around 4-5 times a week (can you believe
                            it!). Firstly, start at a location near where you
                            think it would be, and go from there. <br />
                            <br />
                            For example: If your code could have been 444, then
                            start at 300, 301, 302, 303, etc and go up until you
                            do all the numbers.
                          </>
                        ),
                      },
                      {
                        id: 'didnt-solve-text',
                        type: 'button',
                        text: "Didn't solve your issue?",
                        handle: ({ setValue }) =>
                          setValue((prev) => ({
                            ...prev,
                            'lock-type-select-not-solved': true,
                          })),
                      },
                    ],
                  },
                  {
                    condition: (state) =>
                      state['lock-type-select-not-solved'] === true,
                    value: [
                      fullName,
                      email,
                      orderNumber,
                      productSelectFaulty,
                      message,
                      uploadImage,
                      submitButton,
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Product Questions',
        value: [
          {
            id: 'product-check-text',
            type: 'text',
            text: 'To save time waiting for a response, make sure to check the overview and specifications on the product page you’re inquiring about. This will most likely have all the answers you need!',
          },
          {
            condition: (state) => state['product-didnt-solve'] !== true,
            value: [
              {
                id: 'didnt-solve-text',
                type: 'button',
                text: "Didn't solve your issue?",
                handle: ({ setValue }) =>
                  setValue((prev) => ({
                    ...prev,
                    'product-didnt-solve': true,
                  })),
              },
            ],
          },
          {
            condition: (state) => state['product-didnt-solve'] === true,
            value: [fullName, email, message, uploadImage, submitButton],
          },
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Availability',
        value: [
          {
            id: 'preorder-text',
            type: 'text',
            text: 'All product availability is shown on our product pages. If the product is under the pre-order category, an availability timeline will be shown in orange writing next to the colour selected.',
          },
          {
            condition: (state) => state['availability-didnt-solve'] !== true,
            value: [
              {
                id: 'didnt-solve-text',
                type: 'button',
                text: "Didn't solve your issue?",
                handle: ({ setValue }) =>
                  setValue((prev) => ({
                    ...prev,
                    'availability-didnt-solve': true,
                  })),
              },
            ],
          },
          {
            condition: (state) => state['availability-didnt-solve'] === true,
            value: [fullName, email, message, uploadImage, submitButton],
          },
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Corporate Orders',
        value:[
          fullName, 
          email, 
          phone,
          companyName,
          shippingAddress,
          dateNeeded,
          {
            ...message,
            text: 'What product/s are you interested in? Please specify: - Quantity - Colours - If they need to be personalised'
          }, 
          {
            id: 'preorder-text',
            type: 'text',
            text: 'For custom personalisation, please attach your desired artwork/logos.',
          },
          uploadImage,
          submitButton
        ],
      },
      {
        condition: (state) => state['category-select'] === 'Other',
        value: [fullName, email, message, uploadImage, submitButton],
      },
    ],
  },
]

const flattenArray = (arr) =>
  arr.reduce(
    (flat, next) =>
      flat.concat(Array.isArray(next) ? flattenArray(next) : next),
    []
  )

const checkConditional = (item, state) => {
  if (item.condition !== undefined) {
    return item.condition(state)
  }
  return true
}

function flatten(fd, state, setState) {
  let filteredList = fd.filter((item) => checkConditional(item, state))
  let updatedList = filteredList.map((val, key) => {
    return val.condition === undefined
      ? val
      : flatten(val.value, state, setState)
  })

  return flattenArray(updatedList)
}

export default function getFormData(state, setState) {
  return flatten(formData, state, setState)
}
